




















import Vue, {PropType} from "vue";
 import {mapActions, mapGetters} from "vuex";
 import _ from 'lodash';
 Object.defineProperty(Vue.prototype, '$_', { value: _ });

 export default Vue.extend({
   name: "discount-code" as string,

   props: {
     registerLayout: Boolean as PropType<Boolean>,
     selectedSubscriptionProduct: Number as PropType<number>
   },

   data() {
     return {
       inputSearch: '',
       discountCodeNotFound: false,
       selectedProductId: '',
     }
   },
   methods: {
     ...mapActions("venueSubscriptionModule", {
       fetchDiscountCode: "FETCH_DISCOUNT_CODE",
       setInvalidDiscountCode: "SET_INVALID_DISCOUNT_CODE",
     }),
     searchCodes () {
       const inputSearch: string = this.inputSearch;

       if (inputSearch.length) {
         this.fetchDiscountCode({inputSearch, selectedProductId:this.selectedProductId})
       } else {
         this.$emit("discountCodeFound",null);
         this.setInvalidDiscountCode(false)
       }
     },
   },
   computed: {
     ...mapGetters("venueSubscriptionModule", {
       discountCode: "GET_DISCOUNT_CODE",
       invalidDiscountCode: "GET_INVALID_DISCOUNT_CODE",
       errorDetail: "GET_ERROR_DETAIL",
     }),
   },
   watch: {
     inputSearch: {
       handler(value){
         if (_.isEmpty(value)) {
           this.discountCodeNotFound = true;
           this.$emit("discountCodeFound",null);
           this.setInvalidDiscountCode(false)
         }
       }
     },
     discountCode: {
       handler(value){
         if (_.isEmpty(value)) {
           this.discountCodeNotFound = true;
           this.$emit("discountCodeFound",null);
         } else if (value) {
           this.discountCodeNotFound = false;
           this.$emit("discountCodeFound",value);
         }
       }
     },
     invalidDiscountCode: {
       handler(value){
         if (value) {
           this.discountCodeNotFound = true;
           this.$emit("discountCodeFound",null);
         }
       }
     },
     selectedSubscriptionProduct: {
       handler(value){
         this.selectedProductId = value;
         const inputSearch: string = this.inputSearch;
         if (inputSearch.length) {
           this.fetchDiscountCode({inputSearch, selectedProductId:this.selectedProductId})
         } else {
         this.$emit("discountCodeFound",null)
         }
       },
       immediate:true
     }
   }
 })
