
































import Vue, {PropType} from "vue";
import {PaymentCardData} from "@/types";

export default Vue.extend({
  name: "payment-card-block-new" as string,

  props: {
    data: Object as PropType<PaymentCardData>,
    selectedCard: Number as PropType<number>,
    selectable: Boolean as PropType<boolean>,
  },

  data() {
    return {
      selectedPaymentCard: this.selectedCard
    }
  },

  computed: {
    paymentCardLogo(): string {
      let logo: string = "";

      switch ((this.data.issuerType).toUpperCase()) {
        case "VISA":
          logo = "visa-logo-light.svg";
          break;
        case "MASTERCARD":
          logo = "mastercard-logo.svg";
          break;
        case "AMERICAN EXPRESS":
          logo = "american-express-logo.svg";
          break;
      }

      return require("@/assets/images/payment-cards-logos/" + logo);
    },
    cardName(): string {
      let cardName: string = this.data.issuerType + ' ••••• ' + this.data.lastFourNumbers;

      return cardName.charAt(0).toUpperCase() + cardName.slice(1);
    },
    cardExpiration(): string {
      return this.$t('Expires on') + ' ' + this.data.expirationMonth + ' / ' + this.data.expirationYear.slice(2);
    },
    isSelected(): boolean {
      return this.selectedPaymentCard === this.data.id;
    },
    isExpired(): boolean {
      const date = new Date();
      const expirationYear = parseInt(this.data.expirationYear ?? 0);
      const expirationMonth = parseInt(this.data.expirationMonth ?? 0);
      const expirationDate = new Date(expirationYear, expirationMonth, 1);

      return expirationDate < date;
    }
  },

  methods: {
    setSelectedPaymentCard(cardId: number): void {
      if (this.selectable) {
        this.selectedPaymentCard = cardId;
        this.$emit("change", { paymentId: cardId, paymentType: 1 })
      }
    }
  },

  watch: {
    selectedCard: {
      handler(value: number) {
        if (value === this.data.id) {
          this.selectedPaymentCard = value;
        } else {
          this.selectedPaymentCard = null;
        }
      }
    }
  }
})
