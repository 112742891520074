import { render, staticRenderFns } from "./PaymentCardBlockRedesign.vue?vue&type=template&id=ac424ece&scoped=true&"
import script from "./PaymentCardBlockRedesign.vue?vue&type=script&lang=ts&"
export * from "./PaymentCardBlockRedesign.vue?vue&type=script&lang=ts&"
import style0 from "./PaymentCardBlockRedesign.vue?vue&type=style&index=0&id=ac424ece&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ac424ece",
  null
  
)

export default component.exports