



















































































































































































































import Vue from "vue";
import _ from "lodash";
import Layout from "@/router/layouts/main.vue";
import SubscriptionPlanRedesign from "@/components/add-venue/SubscriptionPlanRedesign.vue";
import AdditionalServices from "@/components/add-venue/AdditionalServices.vue";
import PaymentPeriod from "@/components/add-venue/PaymentPeriod.vue";
import MusicStreamingMethod from "@/components/add-venue/MusicStreamingMethod.vue";
import SummaryPriceRedesign from "@/components/add-venue/SummaryPriceRedesign.vue";
import AddPaymentCardModal from "@/components/modals/AddPaymentCardModal.vue";
import { mapActions, mapGetters } from "vuex";
import {
  EventPlanData,
  SubscriptionProductsData,
  VenueSubscriptionData,
  PaymentCardData,
  BankAccountData,
  SelectedWebstreamSelectVenueData,
} from "@/types";
import { formatUnitPrice } from "@/utils/helpers";
import router from "@/router";
import i18n from "@/i18n/i18n";
import moment from "moment";
import AddBankAccountModal from "@/components/modals/AddBankAccountModal.vue";
import BankAccountCard from "@/components/payments/BankAccountBlock.vue";
import PaymentCardBlockRedesign from "@/components/payments/PaymentCardBlockRedesign.vue";
import DiscountCode from "@/components/add-venue/DiscountCode.vue";
import { loadStripe } from "@stripe/stripe-js";
import { errorMessage } from "@/utils/messages-handlers";

export default Vue.extend({
  name: "subscription-plan-renew" as string,

  components: {
    Layout,
    "subscription-plan-redesign": SubscriptionPlanRedesign,
    "additional-services": AdditionalServices,
    "payment-period": PaymentPeriod,
    "music-streaming-method": MusicStreamingMethod,
    "summary-price-redesign": SummaryPriceRedesign,
    "add-bank-account-modal": AddBankAccountModal,
    "add-payment-card-modal": AddPaymentCardModal,
    "bank-account-card": BankAccountCard,
    "payment-card-block-redesign": PaymentCardBlockRedesign,
    "discount-code": DiscountCode,
  },

  data() {
    return {
      audioMessages: [],
      additionalServicesIds: [],
      webstreamer: 0,
      selectedSubscriptionProduct: null,
      checkboxGdpr: false,
      isSubmitted: false,
      selectedPaymentType: null,
      selectedPaymentMethod: null,
      loader: false,
      randomId: Math.floor(Math.random() * 100000),
      payloadData: {} as object,
      subscriptionId: "" as string,
      discountCodePercentage: 0,
      discountCodeCode: "",
      stripe: null,
      i18: i18n,
    };
  },

  async mounted(): Promise<void> {
    await this.setVenue();
    await this.setSubscriptionProducts();
    await this.setVenueSubscription(router.currentRoute.params.id);
    await this.setPaymentCards();
    await this.setBankAccounts();
    await this.setTaxPricePeriodTranslation();
    await this.setBillingIntervalOnLoad();

    //console.log("SubscriptionPlanRenew selectedSubscriptionProduct", this.selectedVenueSubscription);
    if (!this.taxIsNotValid) await this.setTaxRates();

    if (this.creditCards.length === 0) {
      this.createStripeCustomer();
    }
  },

  computed: {
    ...mapGetters("venueModule", {
      venue: "GET_SELECTED_VENUE",
      taxIsNotValid: "TAX_IS_NOT_VALID",
      getVenues: "GET_VENUES",
    }),
    ...mapGetters("venueSubscriptionModule", {
      subscriptionProducts: "GET_FILTERED_SUBSCRIPTION_PRODUCTS",
      allSubscriptionProducts: "GET_ALL_SUBSCRIPTION_PRODUCTS",
      recurringInterval: "GET_SELECTED_RECURRING_INTERVAL",
      selectedVenueSubscription: "GET_SELECTED_VENUE_SUBSCRIPTION",
      taxRate: "GET_TAX_RATE",
      paymentInterval: "GET_RECURRING_INTERVAL",
      getStripeSubscriptionResponse: "GET_STRIPE_SUBSCRIPTION_RESPONSE",
      invalidDiscountCode: "GET_INVALID_DISCOUNT_CODE",
    }),
    ...mapGetters("companyModule", {
      companyId: "GET_COMPANY_ID",
      company: "GET_COMPANY",
    }),
    ...mapGetters("paymentModule", {
      allPaymentMethods: "GET_PAYMENT_METHODS",
      bankAccounts: "GET_BANK_ACCOUNTS",
      creditCards: "GET_PAYMENT_CARDS",
      stripeKey: "GET_STRIPE_PUBLISHABLE_KEY",
    }),
    ...mapGetters("taxPricePeriodTranslationModule", {
      taxPricePeriodTranslation: "GET_TAX_PRICE_PERIOD_TRANSLATION",
    }),
    isPaymentMethod(): boolean {
      return !!this.selectedPaymentMethod;
    },
    activeSubscriptionProduct(): SubscriptionProductsData {
      if (
        !this.allSubscriptionProducts.length ||
        !this.selectedVenueSubscription.stripeProductWithPriceId
      )
        return;

      return this.allSubscriptionProducts.find(
        p => p.id === this.selectedVenueSubscription.stripeProductWithPriceId
      );
    },
    subscriptionProduct(): Array<SubscriptionProductsData> {
      return this.subscriptionProducts.filter(p => p.songorooServiceType === 1);
    },
    additionalSubscriptionProducts(): SubscriptionProductsData {
      return this.subscriptionProducts.filter(
        p =>
          p.songorooServiceType === 2 &&
          p.recurringInterval == this.paymentInterval &&
          p.isActive == true
      );
    },
    finalPrice(): string {
      if (!this.allSubscriptionProducts || !this.selectedSubscriptionProduct)
        return "";

      // the price per month is always displayed, even if a variant of the year is selected
      const subscriptionProductMonthlyPrice = this.allSubscriptionProducts.find(
        s =>
          s.productName === this.selectedSubscriptionProduct.productName &&
          s.recurringInterval === this.paymentInterval
      ).unitAmount;

      let audioMessagesTotal = 0;
      for (const additionalService of this.audioMessages) {
        audioMessagesTotal += additionalService.unitAmount;
      }
      const taxMultiplier: number = this.taxIsNotValid ? 1.2 : 1;
      const discountCode: number = (100 - this.discountCodePercentage) / 100;

      const price =
        (!!this.selectedSubscriptionProduct
          ? subscriptionProductMonthlyPrice
          : 0) + (audioMessagesTotal != 0 ? audioMessagesTotal : 0);

      return formatUnitPrice(Number(price) * taxMultiplier * discountCode);
    },
    hasAudioMessagesIncluded(): boolean {
      if (!this.selectedVenueSubscription.additionalServices) return false;
      return !!this.selectedVenueSubscription.additionalServices;
    },

    locale() {
      return i18n.locale;
    },

    priceAndPeriodInformation() {
      if (!this.taxPricePeriodTranslation || !this.paymentInterval) return "";
      return this.locale == "en"
        ? this.taxPricePeriodTranslation.priceAndPeriodMonthEn
        : this.taxPricePeriodTranslation
            .isPriceAndPeriodMonthTranslationCompleted
        ? this.taxPricePeriodTranslation.priceAndPeriodMonthSk
        : this.taxPricePeriodTranslation.priceAndPeriodMonthEn;
    },

    taxInfo() {
      if (!this.taxPricePeriodTranslation) return "";
      return this.locale == "en"
        ? this.taxPricePeriodTranslation.taxInformationEn
        : this.taxPricePeriodTranslation.isTaxInformationCompleted
        ? this.taxPricePeriodTranslation.taxInformationSk
        : this.taxPricePeriodTranslation.taxInformationEn;
    },
  },

  methods: {
    ...mapActions("venueModule", {
      setVenue: "FETCH_VENUE",
      updateVenue: "UPDATE_VENUE",
      setSelectedWebstreamVenue: "SET_SELECTED_WEBSTREAM_VENUE",
      setVenues: "FETCH_VENUES",
    }),
    ...mapActions("venueSubscriptionModule", {
      createVenueSubscriptionOnStripe: "CREATE_VENUE_SUBSCRIPTION_ON_STRIPE",
      createVenueSubscription: "CREATE_VENUE_SUBSCRIPTION",
      setSubscriptionProducts: "FETCH_SUBSCRIPTION_PRODUCTS",
      setVenueSubscription: "FETCH_VENUE_SUBSCRIPTION",
      setRecurringInterval: "SET_RECURRING_INTERVAL",
      setTaxRates: "FETCH_TAX_RATE",
      setVenuesSubscription: "FETCH_VENUES_SUBSCRIPTION",
    }),
    ...mapActions("paymentModule", {
      setPaymentCards: "FETCH_PAYMENT_CARDS",
      setBankAccounts: "FETCH_BANK_ACCOUNTS",
      createStripeCustomer: "CREATE_STRIPE_CUSTOMER",
    }),
    ...mapActions("taxPricePeriodTranslationModule", {
      setTaxPricePeriodTranslation: "FETCH_TAX_PRICE_PERIOD_TRANSLATION",
    }),
    setAudioMessages(value: EventPlanData): void {
      this.audioMessages.push(value);
      this.additionalServicesIds.push(value.id);
    },
    removeAdditionalService(value: EventPlanData): void {
      const removeIndex = this.audioMessages
        .map(item => item.id)
        .indexOf(value.id);
      ~removeIndex && this.audioMessages.splice(removeIndex, 1);
      ~removeIndex && this.additionalServicesIds.splice(removeIndex, 1);
    },
    onBillingPeriodicityChange() {
      this.audioMessages = [];
      this.additionalServicesIds = [];
    },
    setWebstreamer(value: boolean): void {
      this.webstreamer = value;
    },
    setSubscriptionProduct(value: SubscriptionProductsData): void {
      this.selectedSubscriptionProduct = value;
    },
    setPayment(value): void {
      if (
        value.paymentType === this.selectedPaymentType &&
        value.paymentId === this.selectedPaymentMethod
      ) {
        this.selectedPaymentType = null;
        this.selectedPaymentMethod = null;
      } else {
        this.selectedPaymentType = value.paymentType;
        this.selectedPaymentMethod = value.paymentId;
      }
    },
    async setBillingIntervalOnLoad() {
      if (!this.company) {
        this.setRecurringInterval("month");
      } else {
        const recurringInterval = this.company.corporate
          ? this.company.billing_interval === 1
            ? "month"
            : "year"
          : "month";
        this.setRecurringInterval(recurringInterval);
      }
    },
    isSelectedSubscriptionIsCanceled(): boolean {
      return (
        this.selectedVenueSubscription.cancelledAt != null ||
        this.selectedVenueSubscription.subscriptionStatus === "canceled"
      );
    },
    isSelectedSubscriptionIncompleteExpired(): boolean {
      return (
        this.selectedVenueSubscription.subscriptionStatus ===
        "incomplete_expired"
      );
    },
    isSelectedSubscriptionExpired(): boolean {
      let currentTimestamp = Math.floor(Date.now() / 1000) as number;
      let subscriptionEndTimestamp = parseInt(
        this.selectedVenueSubscription.subscriptionEndsAt
      ) as number;

      if (
        this.selectedVenueSubscription.isTrial === true &&
        currentTimestamp > subscriptionEndTimestamp
      ) {
        return true;
      }

      return false;
    },
    isSelectedSubscriptionIncomplete(): boolean {
      return this.selectedVenueSubscription.subscriptionStatus === "incomplete";
    },
    isSelectedSubscriptionTrialing(): boolean {
      return (
        this.selectedVenueSubscription.subscriptionstatus === "trialing" ||
        this.selectedVenueSubscription.isTrial === true
      );
    },
    async changeSubscriptionPlan(): Promise<void> {
      this.isSubmitted = true;
      if (!this.isPaymentMethod || this.invalidDiscountCode) return;

      this.subscriptionId = this.selectedVenueSubscription.subscriptionId;
      this.payloadData = {
        stripeProductWithPriceId: this.selectedSubscriptionProduct.id,
        subscriptionId: this.selectedVenueSubscription.subscriptionId,
        isRenew: true,
        taxId: this.selectedVenueSubscription.taxId, // TODO: it is necessary?
        venueOwnerId: this.companyId,
        paymentType: this.selectedPaymentType,
        paymentMethodId: this.selectedPaymentMethod,
        additionalServices: this.additionalServicesIds.join(" "),
        initialFreeTrialDays: 0,
        includedStreamingDevice: this.webstreamer,
        appliedDiscountCode: this.discountCodeCode,
        amount: this.selectedSubscriptionProduct.unitAmount,
        recurringInterval: this.selectedSubscriptionProduct.recurringInterval,
        startOfBillingPeriod: moment().unix(),
        venueId: Number(router.currentRoute.params.id),
      };

      this.loader = true;
      let payloadForUpdateSubscriptionOnCzoneBackend = this.payloadData;

      try {
        if (
          this.isSelectedSubscriptionIsCanceled() ||
          this.isSelectedSubscriptionExpired() ||
          this.isSelectedSubscriptionIncompleteExpired() ||
          this.isSelectedSubscriptionIncomplete() ||
          this.isSelectedSubscriptionTrialing()
        ) {
          // Subscription is canceled, need to create new stripe subscription.
          await this.createVenueSubscriptionOnStripe(this.payloadData);
          const responseOfCreateStripeSubscription =
            this.getStripeSubscriptionResponse;
          payloadForUpdateSubscriptionOnCzoneBackend.subscriptionId =
            responseOfCreateStripeSubscription.stripeSubscriptionData.id;

          if (
            this.getStripeSubscriptionResponse.stripePaymentIntentData &&
            this.getStripeSubscriptionResponse.stripePaymentIntentData
              .status === "requires_action"
          ) {
            this.stripe = await loadStripe(this.stripeKey, {
              locale: this.$i18n.locale,
            });
            const cardPaymentConfirmation =
              await this.stripe.confirmCardPayment(
                this.getStripeSubscriptionResponse.stripePaymentIntentData
                  .client_secret,
                {
                  payment_method:
                    this.getStripeSubscriptionResponse.stripePaymentIntentData
                      .payment_method,
                  setup_future_usage: "off_session",
                }
              );
            if (cardPaymentConfirmation.error) {
              errorMessage(
                "We are unable to authenticate your payment method. Please choose a different payment method and try again.",
                5000
              );
            }
          }
        }

        await this.createVenueSubscription(
          payloadForUpdateSubscriptionOnCzoneBackend
        );

        this.loader = false;

        setTimeout(function () {
          this.setVenueSubscription(router.currentRoute.params.id);
          this.setSubscriptionProducts();
        }, 3500);

        await this.startPlayWebstream();

        this.$router.push({
          path: "/subscription-plan/details/" + router.currentRoute.params.id,
          params: { id: router.currentRoute.params.id },
        });
      } catch (e) {
        this.loader = false;
      }
    },
    async startPlayWebstream(): Promise<void> {
      await this.setVenues({ fetchRatings: false, name: "" });
      await this.setVenuesSubscription();

      const venues = this.getVenues;
      const venuesLength = venues?.length ?? 0;

      if (venuesLength === 1) {
        this.setSelectedWebstreamVenue(null);
        localStorage.removeItem("init_play");

        const venueData: SelectedWebstreamSelectVenueData = {
          id: venues[0].id,
          label: venues[0].name,
        };

        this.setSelectedWebstreamVenue(venueData);
      }
    },
    discountCodeFound(value) {
      if (_.isEmpty(value)) {
        this.discountCodePercentage = 0;
        this.discountCodeCode = "";
      } else {
        this.discountCodePercentage = value.percentageDiscount;
        this.discountCodeCode = value.code;
      }
    },
  },

  watch: {
    activeSubscriptionProduct: {
      handler(value: SubscriptionProductsData): void {
        if (value) {
          this.setRecurringInterval(value.recurringInterval);
        }
      },
    },
    selectedVenueSubscription: {
      handler(value: VenueSubscriptionData): void {
        if (value) {
          this.selectedPaymentType = value.paymentType;
          this.selectedPaymentMethod = value.paymentMethodId;
        }
      },
    },
    companyId: {
      handler(newValue: number, oldValue: number): void {
        if (newValue != oldValue) {
          this.$router.push("/subscription-plan");
        }
      },
    },
    creditCards: {
      handler(cards: Array<PaymentCardData>): void {
        if (cards.length === 1) {
          let cardId: number = cards[0].id;

          this.setPayment({ paymentId: cardId, paymentType: 1 });
          this.selectedPaymentType = 1;
          this.selectedPaymentMethod = cardId;
        }
      },
    },
    bankAccounts: {
      handler(bankAccounts: Array<BankAccountData>): void {
        if (bankAccounts.length === 1) {
          let bankAccountId: number = bankAccounts[0].id;

          this.setPayment({ paymentId: bankAccountId, paymentType: 2 });
          this.selectedPaymentType = 2;
          this.selectedPaymentMethod = bankAccountId;
        }
      },
    },
  },
});
